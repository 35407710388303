.item-list-answer:hover .option-answer {
    visibility: visible !important;
}

.option-answer {
    font-size:  1.3rem;
    color:      #8898AA;
    cursor:     pointer;
    visibility: hidden;
}

.tooltip { pointer-events: none; }