.text-prima {
  color: #14cdef !important; }

.button-prima {
  color: #fff;
  background-color: #14cdef;
  border-color: #14cdef;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.filter-icon { margin: 4px; }

.hidden { visibility: hidden;  }
.visible { visibility: visible;  }

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #14cdef !important;
    border-color: #14cdef !important;
}

.pointable {
  cursor: pointer;
}

.space-bottom {
  margin-bottom: 1%;
}

.to-right {
  float: right !important;
}